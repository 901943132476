'use strict';

// Import our custom CSS
import '../css/style.scss';

// Import only the Bootstrap components we need
import { Util, Collapse, Dropdown, OffCanvas } from 'bootstrap';
import '../common/js/notifications.cookiebar.js';
import lazySizes from 'lazysizes';
import $ from 'jQuery';
import { initPhoneEvent } from './analytics.phone';

$(function () {
    initPhoneEvent('.js_phoneclick');
});
