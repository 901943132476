﻿export function isSiteSecure() {
    return window.location.protocol === 'https:';
}

export function createCookie (name, value, days, sessionOnly, samesite, secureOnly) {
    var ckExpires = '';
    var ckSameSite = '';
    var ckSecureOnly = '';
    if (sessionOnly) {
        ckExpires = '; expires=0';
    } else if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        ckExpires = '; expires=' + date.toGMTString();
    }

    if (samesite) {
        ckSameSite = '; SameSite = ' + samesite;
    } else {
        ckSameSite = '; SameSite = Lax';
    }

    if (secureOnly) {
        ckSecureOnly = '; Secure';
    }

    document.cookie = name + '=' + value + ckExpires + ckSameSite + ckSecureOnly + '; path=/';
};

export function getCookie (name) {
    var nameEq = name + '=';
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
    }

    return null;
};

export function eraseCookie (name) {
    createCookie(name, '', -1);
};