﻿'use strict';

function handlePhoneLinkClicks(evt) {
    if (typeof gtag === 'function') {
        gtag('event', 'phone_number_click', {
            event_category: 'lead',
            event_label: 'Phone Number Click',
        });
    } else {
        console.warn('gtag is not defined. Please load this library **after** loading Google Analytics');
    }
}

export function initPhoneEvent(selector) {
    $(document).on('click', selector, handlePhoneLinkClicks);
}
